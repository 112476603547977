import * as React from "react";
import { Stack, IconButton, Button, Link } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";

export interface IPortfolioTemplateViewProjectProps {
  github?: string;
  itch?: string;
}

export function PortfolioTemplateViewProject(
  props: IPortfolioTemplateViewProjectProps
) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {props.github && (
        <Button
          variant="outlined"
          startIcon={<GitHubIcon />}
          component={Link}
          href={props.github}
        >
          View on GitHub
        </Button>
      )}
      {props.itch && (
        <Button
          variant="outlined"
          startIcon={<VideogameAssetIcon />}
          component={Link}
          href={props.itch}
        >
          View on itch.io
        </Button>
      )}
    </Stack>
  );
}
