import * as React from "react";
import { Image } from "../../../data/types/Image";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { useTheme, Theme } from "@mui/material";
import clientConfig from "../../../../client-config";

export interface IPortfolioTemplateHeaderProps {
  image: any;
}

export function PortfolioTemplateHeader(props: IPortfolioTemplateHeaderProps) {
  const theme = useTheme();
  const imageData = getGatsbyImageData(
    props.image.asset._ref,
    { width: 1140 },
    clientConfig.sanity
  );
  return (
    <GatsbyImage
      image={imageData}
      alt={props.image.alt}
      style={{
        boxShadow: `0 0 25px 0 rgb(36 37 52 / 100%)`,
        width: "100%",
        marginTop: "100px",
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
      }}
    />
  );
}
