import * as React from "react";
import { graphql } from "gatsby";
import { ProjectData } from "../data/types/ProjectData";
import { Layout } from "../components/common/Layout";
import { SectionContainer } from "../components/common/SectionContainer";
import { PortfolioTemplateHeader } from "../components/portfolio/template/PortfolioTemplateHeader";
import { SectionTitle } from "../components/common/SectionTitle";
import { MarkdownBlock } from "../components/common/MarkdownBlock";
import {
  Button,
  Typography,
  Stack,
  IconButton,
  Grid,
  Paper,
} from "@mui/material";
import { PortfolioTemplateViewProject } from "../components/portfolio/template/PortfolioTemplateViewProject";
import { usingMobile } from "../data/usingMobile";
import { PortfolioTemplateFooter } from "../components/portfolio/template/PortfolioTemplateFooter";

interface TemplateData {
  project: ProjectData;
}

export interface IProjectTemplateProps {
  data: TemplateData;
}

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    project: sanityProjects(id: { eq: $id }) {
      id
      projectName
      slug {
        current
      }
      technologies
      _rawThumbnail
      completedDate(formatString: "MMM YYYY")
      githubLink
      itchLink
      _rawBodyText
    }
  }
`;

function ProjectTemplate(props: IProjectTemplateProps) {
  const isMobile = usingMobile();
  return (
    <Layout
      title={props.data.project.projectName}
      includeSocials
      includeHeader
      includeFooter
    >
      <SectionContainer vPadding="40px">
        <PortfolioTemplateHeader image={props.data.project._rawThumbnail} />
        <Paper sx={{ padding: "0 32px", paddingBottom: "32px" }} elevation={0}>
          <Grid container sx={{ marginTop: "20px", marginBottom: "40px" }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                alignItems: "normal",
              }}
            >
              <SectionTitle title={props.data.project.projectName} />
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                display: "flex",
                justifyContent: isMobile ? "flex-start" : "flex-end",
                marginTop: isMobile ? "8px" : 0,
                alignItems: "normal",
              }}
            >
              <PortfolioTemplateViewProject
                github={props.data.project.githubLink}
                itch={props.data.project.itchLink}
              />
            </Grid>
          </Grid>
          <MarkdownBlock data={props.data.project._rawBodyText} />
        </Paper>
        <PortfolioTemplateFooter
          github={props.data.project.githubLink}
          itch={props.data.project.itchLink}
        />
      </SectionContainer>
    </Layout>
  );
}

export default ProjectTemplate;
