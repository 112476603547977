import * as React from "react";
import { usingMobile } from "../../../data/usingMobile";
import { Grid } from "@mui/material";
import { Link } from "gatsby";
import { PortfolioTemplateViewProject } from "./PortfolioTemplateViewProject";

export interface IPortfolioTemplateFooterProps {
  github?: string;
  itch?: string;
}

export function PortfolioTemplateFooter(props: IPortfolioTemplateFooterProps) {
  const isMobile = usingMobile();
  return (
    <>
      <Grid
        container
        sx={{
          marginTop: "30px",
        }}
      >
        <Grid
          item
          lg={3}
          sm={6}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link to="../../#portfolio">Back to portfolio</Link>
        </Grid>
        {isMobile ? null : (
          <Grid
            item
            lg={6}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PortfolioTemplateViewProject
              github={props.github}
              itch={props.itch}
            />
          </Grid>
        )}
        <Grid
          item
          lg={3}
          sm={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Link to="/projects">View all projects</Link>
        </Grid>
      </Grid>
    </>
  );
}
